define("tmp-for-all/templates/worksite-submission/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I16VVept",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite-view\",null,[[\"worksite\",\"conditionGroups\",\"rcaUsers\",\"claimWorksite\",\"addAuditor\",\"selectedConditions\",\"addCondition\",\"removeCondition\",\"saveWorksiteConditions\",\"saveCustomCondition\",\"removeCustomCondition\",\"reviseTmp\",\"declineWorksite\",\"reviewWorksite\",\"issueAccessPermit\",\"createNewDraftTmp\",\"deleteDraftTmp\",\"addComment\",\"createDraft\",\"onEditExtentClicked\",\"rcaAreas\",\"setRCAArea\",\"clashes\",\"clashResolutionOptions\",\"clashTablePermission\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"conditionGroups\"]],[23,[\"model\",\"rcaUsers\"]],[27,\"route-action\",[\"claimWorksite\"],null],[27,\"route-action\",[\"addAuditor\"],null],[23,[\"model\",\"selectedConditions\"]],[27,\"route-action\",[\"addCondition\"],null],[27,\"route-action\",[\"removeCondition\"],null],[27,\"route-action\",[\"saveWorksiteConditions\"],null],[27,\"route-action\",[\"saveCustomCondition\"],null],[27,\"route-action\",[\"removeCustomCondition\"],null],[27,\"route-action\",[\"reviseTmp\"],null],[27,\"route-action\",[\"declineWorksite\"],null],[27,\"route-action\",[\"reviewWorksite\"],null],[27,\"route-action\",[\"issueAccessPermit\"],null],[27,\"route-action\",[\"createNewDraftTmp\"],null],[27,\"route-action\",[\"deleteDraftTmp\"],null],[27,\"route-action\",[\"addComment\"],null],[27,\"route-action\",[\"createDraft\"],null],[27,\"route-action\",[\"transitionToExtentEdit\"],null],[23,[\"model\",\"rcaAreas\"]],[27,\"route-action\",[\"setRCAArea\"],null],[23,[\"model\",\"clashes\"]],[23,[\"model\",\"clashResolutionOptions\"]],[23,[\"model\",\"clashTablePermission\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite-submission/view.hbs"
    }
  });
  _exports.default = _default;
});