define("tmp-for-all/components/shared/rca-clash-resolution-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    authToken: computed.alias('session.data.authenticated.id'),
    rcaClashNote: computed('thisWorksiteResolution.rcaNote', function () {
      var _get;
      return (_get = get(this, 'thisWorksiteResolution.rcaNote')) !== null && _get !== void 0 ? _get : '';
    }),
    rcaClashNoteLength: computed('rcaClashNote', function () {
      var note = get(this, 'rcaClashNote');
      return note.split('').length;
    }),
    actions: {
      closeModal: function closeModal() {
        get(this, 'toggleRcaClashModal')();
      },
      saveRcaClashNote: function saveRcaClashNote() {
        var _this = this;
        var clashId = get(this, 'clashId');
        var thisClashResolutionId = get(this, 'thisWorksiteResolution.id');
        var clashingWorksiteResolutionId = get(this, 'clashingWorksiteResolution.id');
        var rcaNote = get(this, 'rcaClashNote');
        var data = {
          rcaNote: rcaNote
        };
        get(this, 'ajax').updateClashResolution(clashId, thisClashResolutionId, data).catch(function () {
          get(_this, 'flashMessages').error('An error occurred when publishing note');
        }).then(function () {
          if (!clashingWorksiteResolutionId) return;
          get(_this, 'ajax').updateClashResolution(clashId, clashingWorksiteResolutionId, data).catch(function () {
            get(_this, 'flashMessages').error('An error occurred when publishing note');
          });
        }).then(function () {
          get(_this, 'flashMessages').success('This note has been published');
          get(_this, 'toggleRcaClashModal')();
          get(_this, 'refreshClashTable')();
        });
      }
    }
  });
  _exports.default = _default;
});