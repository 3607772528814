define("tmp-for-all/components/app-nav/component", ["exports", "ember-data", "tmp-for-all/config/environment", "jquery", "tmp-for-all/constants"], function (_exports, _emberData, _environment, _jquery, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var computed = Ember.computed,
    setProperties = Ember.setProperties,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['page-header', 'bg-teal'],
    helpAndFaqsUrl: _environment.default.helpAndFaqsUrl,
    auditsPrefix: _environment.default.mwsAuditsPrefix,
    feesPrefix: _environment.default.mwsFeesPrefix,
    searchPrefix: _environment.default.mwsSearchPrefix,
    authManager: Ember.inject.service('session'),
    authorization: Ember.inject.service(),
    store: Ember.inject.service(),
    isSuperAdmin: computed.alias('authManager.currentUser.isAdmin'),
    wasSuperAdmin: computed('authManager.session.authenticator', 'isSuperAdmin', function () {
      return !get(this, 'isSuperAdmin') && get(this, 'authManager.session.authenticator') === 'authenticator:superuser';
    }),
    hasRole: computed('authManager.currentUser.roles.@each', 'isSuperAdmin', function () {
      var roles = get(this, 'authManager.currentUser.roles');
      // Check that roles exists and that pendingPermission is not the only role
      return roles && !(roles.includes(_constants.PENDING_PERMISSION.name) && roles.length === 1);
    }),
    authToken: computed.alias('authManager.data.authenticated.id'),
    auditsUrl: computed('authToken', function () {
      return "".concat(get(this, 'auditsPrefix'), "/mws-verify/").concat(get(this, 'authToken'));
    }),
    auditsReportUrl: computed('authToken', function () {
      return "".concat(get(this, 'auditsPrefix'), "/report/mws-verify/").concat(get(this, 'authToken'));
    }),
    feesUrl: computed('authToken', function () {
      return "".concat(get(this, 'feesPrefix'), "/mws-verify/").concat(get(this, 'authToken'));
    }),
    searchUrl: computed('authToken', function () {
      return "".concat(get(this, 'searchPrefix'), "/mws-verify/").concat(get(this, 'authToken'));
    }),
    canNavigateToAudits: computed('authManager.currentUser.navToAuditsEnabled', function () {
      return get(this, 'auditsUrl') && get(this, 'authManager.currentUser.navToAuditsEnabled');
    }),
    canNavigateToFees: computed('authManager.currentUser.navToFeesEnabled', function () {
      return get(this, 'feesUrl') && get(this, 'authManager.currentUser.navToFeesEnabled');
    }),
    showSuperUserModal: false,
    navItems: computed('authToken', function () {
      var _this = this;
      return _emberData.default.PromiseObject.create({
        promise: Promise.all([get(this, 'canNavigateToAudits'), get(this, 'canNavigateToFees'), get(this, 'isSuperAdmin')]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 3),
            canNavigateToAudits = _ref2[0],
            canNavigateToFees = _ref2[1],
            isSuperAdmin = _ref2[2];
          // The permission checking is not quite good enough to figure out if we can allow
          // the external navigation to mws-audits.  So we need to figure this out seperately
          // and build up the array of nav items in steps.
          var items = [{
            name: 'Planning Map',
            link: 'planning-map.select-region',
            subItems: []
          }];
          if (canNavigateToAudits || isSuperAdmin) {
            var permission = isSuperAdmin ? 'admin' : 'auditor';
            items.push({
              name: 'Audits',
              permission: permission,
              subItems: [{
                name: 'Create Audit',
                external_link: get(_this, 'auditsUrl')
              }, {
                name: 'Report',
                external_link: get(_this, 'auditsReportUrl')
              }]
            });
          }
          if (canNavigateToFees || isSuperAdmin) {
            var _permission = isSuperAdmin ? 'admin' : 'billingManager';
            items.push({
              name: 'Fee Manager',
              permission: _permission,
              subItems: [{
                name: 'Manage Fees',
                external_link: get(_this, 'feesUrl')
              }]
            });
          }
          if (get(_this, 'hasRole')) {
            items.push({
              name: 'Search',
              external_link: get(_this, 'searchUrl')
            });
          }
          items.push({
            name: 'Projects',
            subItems: [{
              name: 'Create Project',
              link: 'projects.create',
              permission: 'createProjects'
            }, {
              name: 'Search Projects',
              link: 'projects.list'
            }]
          }, {
            name: 'Worksites',
            subItems: [{
              name: 'Create Worksite',
              link: 'worksites.create.ownership',
              permission: 'createDraftWorksite'
            }, {
              name: 'My Draft & Published Draft Worksites',
              link: 'worksites.drafts',
              permission: 'createDraftWorksite'
            }, {
              name: 'My Submitted & Declined Worksites',
              link: 'worksites.submissions',
              permission: 'submitWorksite'
            }, {
              name: 'My Accepted Worksites',
              link: 'worksites.list',
              permission: 'submitWorksite'
            }, {
              name: 'Processing Queue',
              link: 'worksites.queue',
              permission: 'camReviewer'
            }, {
              name: 'Search Worksites',
              link: 'worksites.search'
            }]
          }, {
            name: 'TMPs',
            subItems: [{
              name: 'Create TMP',
              link: 'tmps.create',
              permission: 'createDraftTmp'
            }, {
              name: 'My Draft TMPs',
              link: 'tmps.drafts',
              permission: 'createDraftTmp'
            }, {
              name: 'My Submitted TMPs',
              link: 'tmps.submissions',
              permission: 'createDraftTmp'
            }, {
              name: 'My Accepted TMPs',
              link: 'tmps.list',
              permission: 'createDraftTmp'
            }, {
              name: 'Processing Queue',
              link: 'tmps.queue',
              permission: 'claimSubmittedTmp'
            }, {
              name: 'Search TMPs',
              link: 'tmps.search'
            }]
          }, {
            name: 'Conditions',
            permission: 'editConditionsMenu',
            subItems: [{
              name: 'Worksite Conditions',
              link: 'conditions.worksite',
              permission: 'editWorksiteConditions'
            }, {
              name: 'Worksite Condition Groups',
              link: 'condition-groups.worksite',
              permission: 'editWorksiteConditions'
            }, {
              name: 'TMP Conditions',
              link: 'conditions.tmp',
              permission: 'editTmpConditions'
            }, {
              name: 'TMP Condition Groups',
              link: 'condition-groups.tmp',
              permission: 'editTmpConditions'
            }]
          }, {
            name: 'Organisations',
            subItems: [{
              name: 'Create Organisation',
              link: 'companies.create',
              permission: 'createCompany'
            }, {
              name: 'Search Organisations',
              link: 'companies.list'
            }]
          }, {
            name: 'Reports',
            permission: 'viewReportsMenu',
            subItems: [{
              name: 'Billing',
              link: 'reports.billing',
              permission: 'viewBillingReport'
            }, {
              name: 'Worksite Processing Times',
              link: 'reports.worksite-processing-time',
              permission: 'camReviewer'
            }, {
              name: 'TMP Processing Times',
              link: 'reports.tmp-processing-time',
              permission: 'viewTMPProcessingTimes'
            }]
          }, {
            name: 'Deployments',
            subItems: [{
              name: 'My Assigned Deployments',
              link: 'deployments.list',
              permission: 'stms'
            }, {
              name: 'Search Deployments',
              link: 'deployments.search'
            }]
          }, {
            name: 'Admin',
            permission: 'viewCompanyAdminMenu',
            subItems: [{
              name: 'All Users',
              link: 'users.list',
              permission: 'admin'
            }, {
              name: 'Create User',
              link: 'users.create',
              permission: 'admin'
            }, {
              name: 'Invite User',
              link: 'company-admin.invite-user',
              permission: 'viewCompanyAdminMenu'
            }, {
              name: 'Edit Users',
              link: 'company-admin.edit-users',
              permission: 'editUsers',
              hideFromAdmin: true
            }, {
              name: 'Pending Users',
              link: 'company-admin.pending-users',
              permission: 'editUsers',
              domainType: 'company'
            }, {
              name: 'Permissions',
              link: 'admin.permissions',
              permission: 'admin'
            }, {
              name: 'Holidays',
              link: 'rca-admin.holidays',
              permission: 'modifyHolidays'
            }]
          });
          return items;
        })
      });
    }),
    didInsertElement: function didInsertElement() {
      // Click handler for opening/closing main menu on mobile:
      (0, _jquery.default)('body').on('click', '.js-show-menu', function (e) {
        e.preventDefault();
        var t = (0, _jquery.default)(this);
        var target = (0, _jquery.default)(t.data('target'));
        if (target.length) {
          if (target.hasClass('on')) {
            target.css('height', '0').removeClass('on');
          } else {
            var targetInner = target.children(':first');
            if (targetInner.length) {
              target.css('height', targetInner.outerHeight(true)).addClass('on');
            }
          }
        }
      });

      // Click handler for opening/closing sub menus on mobile:
      (0, _jquery.default)('body').on('click touchend', '.js-show-sub', function (e) {
        var showMenuBtn = (0, _jquery.default)('.js-show-menu');
        var showSubBtns = (0, _jquery.default)('.js-show-sub');
        e.preventDefault();
        var t = (0, _jquery.default)(this);
        var target = t.siblings(t.data('target')).eq(0);
        var mainMenu = (0, _jquery.default)(showMenuBtn.data('target'));
        if (target.length && mainMenu.length) {
          var targetInner = target.children(':first');
          var targetInnerHeight = targetInner.outerHeight(true);
          if (target.hasClass('on')) {
            target.css('height', '0').removeClass('on');
            t.removeClass('on');
            mainMenu.css('height', mainMenu.outerHeight(true) - targetInnerHeight);
          } else {
            var activeSubMenuBtns = showSubBtns.filter('.on');
            var delay = activeSubMenuBtns.length ? 500 : 0;
            activeSubMenuBtns.trigger('click');
            setTimeout(function () {
              target.css('height', targetInner.outerHeight(true)).addClass('on');
              t.addClass('on');
              mainMenu.css('height', mainMenu.outerHeight(true) + targetInnerHeight);
            }, delay);
          }
        }
      });

      // Reset menu classes/inline styles if window width changes
      var initialWindowWidth = (0, _jquery.default)(window).width();
      (0, _jquery.default)(window).resize(function () {
        var curWindowWidth = (0, _jquery.default)(window).width();
        if (initialWindowWidth !== curWindowWidth) {
          if (curWindowWidth >= 768) {
            var showMenuBtn = (0, _jquery.default)('.js-show-menu');
            var showSubBtns = (0, _jquery.default)('.js-show-sub');
            var btns = showMenuBtn.add(showSubBtns);
            var menuContainers = (0, _jquery.default)(showMenuBtn.data('target')).add((0, _jquery.default)(showSubBtns.data('target')));
            btns.add(menuContainers).removeClass('on');
            menuContainers.removeAttr('style');
          }
        }
      });

      // Close menu when link is clicked and route transitions (probably a better way to do this?)
      (0, _jquery.default)('body').on('click', '.main-nav-wrap a', function (e) {
        if ((0, _jquery.default)(window).width() < 768) {
          (0, _jquery.default)('.js-show-menu').trigger('click');
        }
      });

      // Open user menu on mobile (handle with css hover on desktop):
      (0, _jquery.default)('body').on('click touchend', '.user-menu-trigger', function (e) {
        e.preventDefault();
        if ((0, _jquery.default)(window).width() < 768) {
          var userMenu = (0, _jquery.default)('.user-options');
          if (userMenu.hasClass('js-active')) {
            userMenu.removeClass('js-active');
          } else {
            userMenu.addClass('js-active');
          }
        }
      });
    },
    actions: {
      logout: function logout() {
        this.get('authManager').invalidate();
      },
      didSelectOperatingAs: function didSelectOperatingAs(domain, id) {
        if (domain === 'null' && id === 'null') {
          domain = null;
          id = null;
        }
        var user = get(this, 'authManager.currentUser');
        return user.then(function (usr) {
          setProperties(usr, {
            selectedDomain: domain,
            selectedDomainId: id
          });
          return usr.save().then(function () {
            window.location.reload();
          });
        });
      },
      toggleSuperModal: function toggleSuperModal() {
        set(this, 'showSuperUserModal', !get(this, 'showSuperUserModal'));
      },
      becomeSuperUser: function becomeSuperUser(password) {
        set(this, 'showSuperUserModal', false);
        get(this, 'authManager').authenticate('authenticator:superuser', -1, password).then(function () {
          return window.location.reload();
        }).catch(function () {
          return window.location.reload();
        });
      }
    }
  });
  _exports.default = _default;
});