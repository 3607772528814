define("tmp-for-all/components/worksite-view/clashes-list/clash-item/component", ["exports", "tmp-for-all/classes/file-wrapper", "tmp-for-all/constants"], function (_exports, _fileWrapper, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    tagName: '',
    getResolutionFiles: function getResolutionFiles() {
      var _this = this;
      return get(this, 'store').findRecord('clash-resolution', this.get('clash.thisResolution.id')).then(function (resolution) {
        return _fileWrapper.FileWrapperArrayProxy.create({
          content: resolution.files
        });
      }).then(function (files) {
        return set(_this, 'files', files);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.getResolutionFiles();
    },
    clashModalShown: false,
    rcaClashModalShown: false,
    currentUser: computed.alias('authManager.currentUser'),
    isCurrentUserRca: computed.equal('currentUser.selectedDomain', 'jurisdiction'),
    files: [],
    overlaps: computed('overlapsDebugIsFlagged', 'clash.overlaps', function () {
      var debugEnabled = get(this, 'overlapsDebugIsFlagged');
      var isSuperAdmin = get(this, 'currentUser.isAdmin');
      if (debugEnabled === true && isSuperAdmin) return get(this, 'clash.debugOverlaps');
      return get(this, 'clash.overlaps');
    }),
    combinedStatusClass: computed('clash.combinedStatus', function () {
      switch (get(this, 'clash.combinedStatus')) {
        case _constants.CLASH_RESOLUTION_STATUS_LABEL.RESOLVED:
          return 'label-green';
        case _constants.CLASH_RESOLUTION_STATUS_LABEL.PARTLY_RESOLVED:
          return 'label-orange';
        case _constants.CLASH_RESOLUTION_STATUS_LABEL.UNRESOLVED:
          return 'label-red';
      }
    }),
    hasResolved: computed('clash.thisResolution.status', function () {
      return get(this, 'clash.thisResolution.status') !== 4;
    }),
    actions: {
      toggleClashModal: function toggleClashModal() {
        this.toggleProperty('clashModalShown');
      },
      toggleRcaClashModal: function toggleRcaClashModal() {
        this.toggleProperty('rcaClashModalShown');
      }
    }
  });
  _exports.default = _default;
});