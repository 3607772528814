define("tmp-for-all/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    documentationBlock: belongsTo('documentation-block', {
      async: true
    }),
    layout: belongsTo('layout', {
      async: true
    }),
    clashResolution: belongsTo('clash-resolution', {
      async: true
    }),
    filename: attr('string'),
    size: attr('string'),
    versions: attr(),
    isPrivate: attr('boolean-nullable', {
      defaultValue: null
    }),
    privateReason: attr('string')
  });
  _exports.default = _default;
});